/*import { LogoTwilioIcon } from "@twilio-paste/icons/esm/LogoTwilioIcon";
import React from "react";
import { IconSize, TextColor } from "@twilio-paste/style-props";

export const LOGO_TITLE = "Foresee Health Conversations";
export const LOGO_SUB_TITLE = "Demo application";
export const APP_TITLE = "Foresee Health Conversations";

interface LogoTwilioIconProps {
  decorative?: boolean;
  color?: TextColor | undefined;
  size?: IconSize | undefined;
  title?: string;
}

export const AppLogo: React.FC<LogoTwilioIconProps> = ({
  decorative = false,
  color = "colorTextBrandHighlight",
  size = "sizeIcon40",
  title = "app logo",
}) => (
  <LogoTwilioIcon
    decorative={decorative}
    color={color}
    size={size}
    title={title}
  />
);
*/
import React from "react";
import ForeseeHealthLogo, {
  ForeseeHealthIcon,
} from "../src/components/icons/ForeseeHealthLogo";

export const LOGO_TITLE = "Foresee Health Conversations";
export const LOGO_SUB_TITLE = "";
export const APP_TITLE = "Foresee Health Conversations";

interface AppLogoProps {
  showFullLogo?: boolean;
  width?: number;
  height?: number;
  className?: string;
  title?: string;
}

export const AppLogo: React.FC<AppLogoProps> = ({
  showFullLogo = true,
  width = 40,
  height = 40,
  className = "",
  title = "Foresee Health logo",
}) => (
  <ForeseeHealthLogo
    showFullLogo={showFullLogo}
    width={width}
    height={height}
    className={className}
    aria-label={title}
  />
);

export const AppIcon: React.FC<AppLogoProps> = ({
  width = 40,
  height = 40,
  className = "",
  title = "Foresee Health icon",
}) => (
  <ForeseeHealthIcon
    width={width}
    height={height}
    className={className}
    aria-label={title}
  />
);
