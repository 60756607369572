import React from "react";

interface LogoProps {
  width?: number;
  height?: number;
  className?: string;
  showFullLogo?: boolean;
  decorative?: boolean;
  size?: string;
  color?: string;
}

export const ForeseeHealthIcon: React.FC<LogoProps> = ({
  width = 40,
  height = 40,
  className = "",
  decorative = true,
}) => {
  return (
    <img
      src="/foresee-health-icon.png"
      alt="Foresee Health Icon"
      width={width}
      height={height}
      className={className}
      aria-hidden={decorative}
    />
  );
};

export const ForeseeHealthFullLogo: React.FC<LogoProps> = ({
  width = 200,
  height = 40,
  className = "",
}) => {
  return (
    <img
      src="/foresee-health-full-logo.png"
      alt="Foresee Health Logo"
      width={width}
      height={height}
      className={className}
    />
  );
};

export const ForeseeHealthLogo: React.FC<LogoProps> = ({
  width,
  height,
  className = "",
  showFullLogo = true,
}) => {
  return showFullLogo ? (
    <ForeseeHealthFullLogo
      width={width}
      height={height}
      className={className}
    />
  ) : (
    <ForeseeHealthIcon width={width} height={height} className={className} />
  );
};

export default ForeseeHealthLogo;
